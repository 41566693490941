@import '../App.scss';

.app.dark{
    background-color: #1f1f1f !important;
    color: #fff !important;
    // direction: rtl;
    .topbar{
        box-shadow: 0px 0px 6px 0px rgba(201, 201, 201, 0.47) !important;
    }
    .show-print {
        background-color: #1f1f1f !important;
    }
    .main-page-cou .card {
        color: #fff !important;
        background-color: #1f1f1f !important;
        box-shadow: 0px 0px 6px 0px rgba(201, 201, 201, 0.47) !important;
    }
    .main-page-cou .card .title{
        color : #fff !important
    }
    .two-box{
        box-shadow: 0px 0px 6px 0px rgba(201, 201, 201, 0.47) !important;
    }
    .footerbar{
        box-shadow: 0px 0px 6px 0px rgba(201, 201, 201, 0.47) !important;
    }
    .big{
        background-color: #1f1f1f !important;
        color: #fff !important;
        box-shadow: 0px 0px 6px 0px rgba(201, 201, 201, 0.47) !important;
        a{
            color: #fff !important
        }
    } .big2{
        background-color: #1f1f1f !important;
        color: #fff !important;
        box-shadow: 0px 0px 6px 0px rgba(201, 201, 201, 0.47) !important;
        a{
            color: #fff !important
        }
    }
    .even-row {
        background-color: #1f1f1f !important;
        color: #fff !important
      }
      
      .odd-row {
        background-color: #1f1f1f !important;
        color: #fff !important
      }
      .css-yrdy0g-MuiDataGrid-columnHeaderRow {
        color:  #fff !important
    }
    .css-rtrcn9-MuiTablePagination-root:last-child{
        color:  #fff !important;
        svg{
            color:  #fff !important; 
        }
    }
    .footerbar{
        color:  #fff !important; 
    }
}


.Toastify__toast-container {
    z-index: 99999999999999 !important;
}



.two-box{
    padding: 10px 13px;
    min-height: 695px;
    // position: relative;
    // z-index: -1;
    overflow: hidden;
    box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);



    .add-header{
        padding: 5px 3px 20px 3px !important; 
        border-bottom: 1px solid lightgray;
    }
    .main-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 5px;
        // position: relative;
        // z-index: -1;

    .btn-list{
        display: flex;
        justify-content: flex-end;
        align-content: center;
        flex-wrap: wrap;
        gap: 3px;
         .btn{
        background-color:   $main-color;
        color: #fff;
        padding: 4px 13px;
        margin-right: 10px;
        transition: all 0.2s ease-in ;
    }.btn:hover{
        background-color: $second-color;
    }
    .btn-delete{
        background-color: crimson;
        color: #fff;
        padding: 4px 13px;
        margin-right: 10px;
        transition: all 0.2s ease-in ;
    }.btn-delete:hover{
        background-color: rgb(242, 54, 92);
    }
 }
}


}
.btn-lest{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
}
.btn-danger-rgba{
    background-color: gray !important;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-primary-rgba{
    background-color: $main-color;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-primary-rgba:hover{
    background-color: $main-color;
    color: #fff;
}

.btn-danger-rgba:hover{
    background-color: gray !important;
    color: #fff;
}

.form-group{
    p{
        margin: 0;
    }
    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked,.css-1nr2wod.Mui-checked{
        color: $main-color
    }
    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,.css-1nr2wod.Mui-checked + .MuiSwitch-track{
        background-color: #197001;

    }
    margin-bottom: 8px;
    label{
        margin-bottom: 2px;
        .redstar{
            color: red;
        }
    }
    p{
        margin: 5px 0;
        font-weight: 500;
        font-size: 1rem;
        .redstar{
        
            color: red;
        } 
    }
    small{
        font-size: 12px; font-weight: 500;margin-left: 5px;
    }
}


.cellWithImg{
    .boxImageEmpty{
        .cellImg{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    margin-right: 10px;
    object-fit: scale-down;
    background: #F4F4F4;

        }
    }
}

.form-group{
    label{
        margin: 5px 0;
        font-weight: 500;
        // color: darkslategray;
    }
    input{

    }
    input:focus { 
        outline: none;
    }
    // .MuiInputLabel-formControl{
    //     margin-top: -19px !important;
    // }
    .b6e5{
        width: 90px;
        height: 90px;
        border-radius: 100%;
        object-fit: fill;
    }

    .icon{
        cursor: pointer;
        color: gray !important;
       }
}

.form-control:focus {
    border-color: $main-color;
    box-shadow: none
}
.img-upload{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 10px;
    .switch{
        flex: 2;
    }
    .form-group{
        margin-bottom: 0;
    }
}



.boxImageChar{
    span{
    width: 50px !important;
    height: 50px !important;
    font-size: 20px;
    border-radius: 50%;
    background-color: $main-color;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
}

.box-icon-delete{
    background-color: rgb(255 0 0 / 60%);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        color: #fff;
        width: 40px;
        height: 40px;
    }
}


.btn-delete{
    background-color: crimson;
        color: #fff;
        padding: 6px 15px;
        margin-right: 10px;
        transition: all 0.2s ease-in ;
}
.btn-delete:hover{
    background-color: rgb(242, 54, 92);
    color: #fff;
}

.btn-cancle{
    background-color: gray ;
    color: #fff;
    padding: 6px 15px;
    margin-right: 10px;
    transition: all 0.2s ease-in ;
    &:hover{
        background-color: rgba(128, 128, 128, 0.817);
        color: #fff;
    }
}

.css-wgai2y-MuiFormLabel-asterisk{
    color: red;
}

.redstar{
        
    color: red;
} 


.icon-list-print{
    position: relative;
    margin: auto;
    cursor: pointer;
    .show-print{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        width: 150px;
        box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
        background-color: #fff;
        z-index: 999;
        position: absolute;
        padding: 10px 0;
        left: -58px;
        border-radius: 8px;
        top: 27px;
        span{
            padding: 2px 10px;
            width: 100%;
            cursor: pointer;
            transition: all 0.3 ease;
        }:hover{
            background-color: gray;
            color: #fff;
        }
    }
}